import { defineStore } from "pinia"
import { ConfigService } from "@/shared/services/config"
import { AttributeCategory, Enum, Prototype, Theme } from "@/shared/models/config"
import { useMessageThemeStore } from "./message-theme"
import { useAgencyThemeStore } from "./agency-theme"
import { isMessageTheme, isAgencyTheme } from "@/shared/utils/helpers"
import { MessageTheme } from "@/shared/models/message-theme"
import { AgencyTheme } from "@/shared/models/agency-theme"
import { TemplatePayload } from "@/shared/models/template"
import { setColorShades } from "@/shared/utils/color-helpers"
import { Integration } from "@/shared/models/integration"
import { PropertyGroup } from "@/shared/models/property-group-layout"

export const useConfigStore = defineStore("config", {
    state: () => ({
        configService: new ConfigService(),
        enums: [] as Enum[],
        prototypes: [] as Prototype[],
        signature: {} as TemplatePayload,
        attributeCategories: [] as AttributeCategory[],
        integrations: [] as Integration[],
        crmPropertyGroups: [] as PropertyGroup[],
        cmsAdvancedSearchGroups: [] as PropertyGroup[],
        messageThemeStore: useMessageThemeStore(),
        agencyThemeStore: useAgencyThemeStore(),
    }),
    actions: {
        async getConfig() {
            try {
                const config = await this.configService.getConfig()
                if (config) {
                    this.enums = config.enums || []
                    this.prototypes = config.prototypes || []
                    this.attributeCategories = config.attributeCategories || []
                    this.integrations = config.integrations || []
                    this.signature = config.signature || ""
                    this.crmPropertyGroups = config.crmPropertyGroupLayout.groups || []
                    this.cmsAdvancedSearchGroups = config.cmsAdvancedSearchGroupLayout.groups || []

                    if (Array.isArray(config.themes)) {
                        this.messageThemeStore.messageThemes = this.getMessageTheme(config.themes)
                        this.agencyThemeStore.agencyTheme = this.getAgencyTheme(config.themes)
                        if (this.agencyThemeStore.agencyTheme) {
                            setColorShades("--primary-color", this.agencyThemeStore.agencyTheme.primaryButtonColor)
                            setColorShades("--link-color", this.agencyThemeStore.agencyTheme.linkColor)
                            localStorage.setItem(
                                "--primary-color",
                                this.agencyThemeStore.agencyTheme.primaryButtonColor || ""
                            )
                            localStorage.setItem("--link-color", this.agencyThemeStore.agencyTheme.linkColor || "")
                        }
                    }
                }
            } catch (error) {
                console.log("[getConfig] error", error)
            }
        },

        getPrototype(typeName: string) {
            return this.prototypes?.find((prototype) => prototype.typeName === typeName) as Prototype
        },

        getAgencyTheme(themes: Theme[]) {
            return themes?.find((theme) => isAgencyTheme(theme)) as AgencyTheme
        },

        getMessageTheme(themes: Theme[]) {
            if (!themes || !Array.isArray(themes)) {
                return []
            }

            return themes.filter((theme) => {
                if (isMessageTheme(theme)) return theme
            }) as MessageTheme[]
        },
    },
    getters: {
        sources: (state) => {
            return state.enums.find((enumObject) => enumObject.name === "leadSource")
        },
        tags: (state) => {
            return state.enums.find((enumObject) => enumObject.name === "leadTag")
        },
        leadStages: (state) => {
            return state.enums.find((enumObject) => enumObject.name === "leadStage")
        },
    },
})
