<template>
    <template v-if="isDataFetched">
        <!-- Phone on call bar -->
        <PhoneOnCallBar
            v-if="isPhoneTopBarVisible"
            @onCloseCall="closeCall"
            @onOpenSidebar="openPhoneSidebar"
        ></PhoneOnCallBar>

        <!-- Task session bar -->
        <GgmsTaskSessionBar v-if="isTaskSessionStarted" />

        <!-- Static sidebar for desktop -->
        <div
            :class="[
                'flex flex-col fixed h-full transition-all duration-500 ease-in-out',
                isCollapsed ? 'w-16' : 'w-64',
            ]"
            v-if="!isMobileScreen"
            style="z-index: 40"
        >
            <div class="flex flex-col justify-between h-full">
                <GgmsNavigation :items="navigation" :collapsed="isCollapsed" @menuItemClick="handleMenuClick" />

                <!-- Collapse/Expand Arrow Container -->
                <div
                    @click="toggleSidebar"
                    class="w-6 h-6 bg-white border rounded-full flex justify-center items-center cursor-pointer transition-transform duration-300 ease-in-out hover:bg-primary-color-600 hover:text-white focus:ring focus:ring-primary-color absolute bottom-36 -right-3 group"
                    :class="{ 'rotate-180': isCollapsed }"
                    role="button"
                    aria-label="Collapse Sidebar"
                >
                    <component
                        :is="ArrowIcon"
                        class="w-4 h-4 text-primary-color-600 transition-transform duration-300 ease-in-out group-hover:text-white"
                        :class="{ 'transform rotate-180': isCollapsed }"
                    />
                </div>
            </div>
        </div>

        <!-- Main content area -->
        <div
            :class="[
                isCollapsed ? 'md:pl-16' : 'md:pl-64',
                'flex flex-col flex-1 transition-all duration-300 ease-in-out z-50',
            ]"
        >
            <!-- Main top bar -->
            <div class="sticky z-40 flex-shrink-0 flex h-fit bg-white shadow" :class="mainTopBarTopClass">
                <!-- Mobile header and sidebar -->
                <MobileSidebarAndHeader
                    v-if="isMobileScreen"
                    :navigation="navigation"
                    @openPhoneSidebar="openPhoneSidebar"
                    @openAddLeadModal="openAddLeadModal"
                    @openNotificationSidebar="openNotificationSidebar"
                />

                <!-- Desktop header -->
                <div v-else class="flex gap-x-4 w-full pl-2 pr-6">
                    <GgmsInput
                        v-model="searchTerm"
                        placeholder="Search"
                        :iconStart="SearchIcon"
                        inputClass="border-0 shadow-transparent h-16 focus:ring-0 focus:border-0"
                        class="w-full"
                    />

                    <MenuActionItems
                        @openPhoneSidebar="openPhoneSidebar"
                        @openAddLeadModal="openAddLeadModal"
                        @openNotificationSidebar="openNotificationSidebar"
                    />
                </div>
            </div>

            <!-- Main content -->
            <main class="flex-1">
                <div class="overflow-auto">
                    <slot />
                </div>
            </main>
        </div>

        <!-- Modals and sidebars -->
        <AddLeadModal :open="isAddLeadModalOpen" :lead="phoneCallLead" @closeModal="closeAddLeadModal()" />
        <PhoneCallSidebar v-model="isPhoneSidebarOpen" />
        <NotificationSidebar v-model="isNotificationSidebarOpen" />
        <FilterSidebar />
        <EmailVerificationModal
            :open="isAgencyNewlyRegistered"
            @closeModal="closeEmailVerificationModal"
        ></EmailVerificationModal>
    </template>
</template>

<script lang="ts" setup>
import { ref, computed, onMounted, watch, nextTick } from "vue"
import GgmsNavigation from "@/components/GgmsNavigation.vue"
import GgmsTaskSessionBar from "@/components/GgmsTaskSessionBar.vue"
import AddLeadModal from "@/components/AddLeadModal.vue"
import EmailVerificationModal from "@/components/email-verification/EmailVerificationModal.vue"
import PhoneCallSidebar from "@/components/phone-sidebar/PhoneCallSidebar.vue"
import PhoneOnCallBar from "@/components/phone-sidebar/PhoneOnCallBar.vue"
import NotificationSidebar from "@/components/notifications/NotificationsSidebar.vue"
import MobileSidebarAndHeader from "@/components/MobileSidebarAndheader.vue"
import MenuActionItems from "@/components/MenuActionItems.vue"
import GgmsInput from "@/components/GgmsInput.vue"
import FilterSidebar from "@/components/filters/FilterSidebar.vue"
import { ArrowLeftIcon, ArrowRightIcon } from "@heroicons/vue/outline"

import {
    SearchIcon,
    HomeIcon,
    UsersIcon,
    InboxInIcon,
    TagIcon,
    ClipboardCheckIcon,
    ChartBarIcon,
    CogIcon,
    DocumentTextIcon,
    PresentationChartLineIcon,
    ClipboardListIcon,
    RefreshIcon,
    FireIcon,
    OfficeBuildingIcon,
} from "@heroicons/vue/outline"
import CodeSquareIcon from "@/components/icons/CodeSquareIcon.vue"

import { getUser, deleteCookie, getCookieValue, hasUserPermission } from "@/shared/utils/helpers"
import { useTaskStore } from "@/stores/task"
import { useTagStore } from "@/stores/tag"
import { useAuthStore } from "@/stores/auth"
import { useAgencyStore } from "@/stores/agency"
import { usePhoneCallStore } from "@/stores/phone-call"
import { useConfigStore } from "@/stores/config"
import { useAgentsStore } from "@/stores/agents"
import { useSocketStore } from "@/stores/socket"
import { useLeadsStore } from "@/stores/leads"
import { useOriginStore } from "@/stores/origin"
import { PhoneCall } from "@/shared/models/phone-call"
import { ResourceTypeName, Role } from "@/shared/models/role"
import router from "@/router"

const taskStore = useTaskStore()
const tagStore = useTagStore()
const authStore = useAuthStore()
const agencyStore = useAgencyStore()
const phoneCallStore = usePhoneCallStore()
const configStore = useConfigStore()
const agentsStore = useAgentsStore()
const socketStore = useSocketStore()
const leadStore = useLeadsStore()
const originStore = useOriginStore()

let navigation = [
    { name: "Dashboard", icon: HomeIcon, path: "/dashboard" },
    { name: "People", icon: UsersIcon, path: "/people" },
    { name: "Listings", icon: OfficeBuildingIcon, path: "/listings" },
    { name: "Inbox", icon: InboxInIcon, path: "/inbox" },
    { name: "Deals", icon: TagIcon, path: "/deals" },
    { name: "Tasks", icon: ClipboardCheckIcon, path: "/tasks" },
    { name: "Reports", icon: ChartBarIcon, path: "/reports" },
    {
        name: "Templates",
        icon: DocumentTextIcon,
        path: "/templates",
    },
    {
        name: "Workflows",
        icon: FireIcon,
        path: "/workflows",
    },
    { name: "Forms", icon: ClipboardListIcon, path: "/forms" },
    {
        name: "Developers",
        icon: CodeSquareIcon,
        children: [
            {
                name: "API Keys",
                path: "/developers",
            },
        ],
    },
    {
        name: "Settings",
        icon: CogIcon,
        children: [
            {
                name: "My Settings",
                path: "/my-settings",
            },
            {
                name: "Agencies",
                path: "/agencies",
            },
            {
                name: "MLS Feeds",
                path: "/mls-feeds",
            },
            {
                name: "Domains",
                path: "/domains",
            },
            {
                name: "Properties",
                path: "/properties",
            },
            {
                name: "Phone Numbers",
                path: "/phone-numbers",
            },
            {
                name: "Tags",
                path: "/tags",
            },
            {
                name: "Origins",
                path: "/origins",
            },
            {
                name: "Lists",
                path: "/lists",
            },
            {
                name: "Themes",
                path: "/themes",
            },
            {
                name: "Integrations",
                path: "/integrations",
            },
        ],
    },
    { name: "Team", icon: UsersIcon, path: "/team" },
    { name: "Audit", icon: PresentationChartLineIcon, path: "/audit" },
    { name: "Event Logs", icon: PresentationChartLineIcon, path: "/logs" },
    { name: "Jobs", icon: RefreshIcon, path: "/jobs" },
]

// Collapse/Expand sidebar
const isCollapsed = ref(false)
const ArrowIcon = computed(() => (isCollapsed.value ? ArrowRightIcon : ArrowLeftIcon))

const isDataFetched = ref(false)
const isPhoneSidebarOpen = ref(false)
const isNotificationSidebarOpen = ref(false)
const isAgencyNewlyRegistered = ref(false)
const searchTerm = ref("")

const isPhoneCallInProgress = computed(() => ["calling", "ongoing"].includes(phoneCallStore.phoneCallState))
const isPhoneTopBarVisible = computed(() => isPhoneCallInProgress.value && !isPhoneSidebarOpen.value)
const mainTopBarTopClass = computed(() => (isPhoneTopBarVisible.value ? "top-[66px]" : "top-0"))
const isAddLeadModalOpen = computed(() => leadStore.isAddLeadModalOpen)
const phoneCallLead = computed(() => phoneCallStore.currentPhoneCall?.leads?.[0])
const currentUser = computed(() => authStore.currentUser)
const isTaskSessionStarted = computed(() => taskStore.isActiveTaskSession)

const isMobileScreen = computed(() => window.screen.width <= 768)

// Toggle sidebar collapse/expand
function toggleSidebar() {
    isCollapsed.value = !isCollapsed.value
}

// Handle menu item click
function handleMenuClick(menuItem: any) {
    const currentRoute = router.currentRoute.value.path
    const isSubpage = menuItem.children?.some((child: { name: string; path: string }) => child.path === currentRoute)

    if (isSubpage) {
        isCollapsed.value = false

        return
    }

    if (menuItem.name === "Settings" || menuItem.name === "Developers") {
        isCollapsed.value = false

        nextTick(() => {
            const menuItemElement = document.getElementsByName(menuItem.name)[0]
            if (menuItemElement) {
                menuItemElement.click()
            }
        })
    }
}

async function openAddLeadModal() {
    tagStore.tableState.typeName = "lead"
    await originStore.getAllOrigins()
    leadStore.isAddLeadModalOpen = true
}

function closeAddLeadModal() {
    leadStore.isAddLeadModalOpen = false
    phoneCallStore.currentPhoneCall = {} as Partial<PhoneCall>
}

function openPhoneSidebar() {
    if (phoneCallStore.phoneCallState === "idle") {
        phoneCallStore.getCalls()
        tagStore.getPhoneCallTags()
    }
    isPhoneSidebarOpen.value = true
}

function closeCall() {
    phoneCallStore.phoneCallState = "finished"
    phoneCallStore.disconnectTwilio()
}

function openNotificationSidebar() {
    isNotificationSidebarOpen.value = true
}

function closeEmailVerificationModal() {
    isAgencyNewlyRegistered.value = false
    deleteCookie("isAgencyNewlyRegistered")
}

function checkIfAgencyIsNewlyRegistered() {
    const isAgencyNewlyRegisteredCookie = getCookieValue("isAgencyNewlyRegistered")

    // if the email is confirmed, emailConfirmation will be undefined and we explicitly check the value for false
    isAgencyNewlyRegistered.value =
        currentUser.value?.emailConfirmation?.confirmed === false && isAgencyNewlyRegisteredCookie === "true"
}

onMounted(async () => {
    await Promise.all([
        agencyStore.loadAgency(),
        agencyStore.getAvailableAgencies(),
        taskStore.getActiveTaskSession(),
        configStore.getConfig(),
        agentsStore.getMe(),
        tagStore.getCachedTags(),
        originStore.getCachedOrigins(),
        socketStore.connectSocket(),
        socketStore.connectJobs(),
        agentsStore.loadAgents(),
    ])
    const user = getUser()
    authStore.currentUser = user
    isDataFetched.value = true
    // filter out those navigation items that doesn't have permission
    navigation = navigation.filter((item) => hasUserPermission(user?.role as Role, item.name as ResourceTypeName))
    checkIfAgencyIsNewlyRegistered()
})

watch(
    () => leadStore.isAddLeadModalOpen,
    (value) => {
        if (!value) {
            return
        }
        isPhoneSidebarOpen.value = false
        phoneCallStore.phoneCallState = "idle"
        isNotificationSidebarOpen.value = false
    }
)
</script>
