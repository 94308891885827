import { Client } from "./client"
import Crud from "./crud"
import { Workflow } from "../models/workflow"

const client = new Client()

export class WorkflowService extends Crud<Workflow> {
    constructor() {
        super("crm/workflows")
    }

    createWorkflow(workflow: Workflow): Promise<Workflow> {
        return client.post(this.endpoint, workflow)
    }

    updateWorkflow(id: string, workflow: Partial<Workflow>): Promise<{ workflow: Workflow }> {
        return client.patch(`${this.endpoint}/${id}`, workflow)
    }

    pauseWorkflow(id: string) {
        return client.put(`${this.endpoint}/pause/${id}`)
    }

    resumeWorkflow(id: string) {
        return client.put(`${this.endpoint}/resume/${id}`)
    }

    getWorkflows(): Promise<Workflow[]> {
        return client.get(this.endpoint)
    }

    getWorkflowsWidget(leadId: string) {
        return client.get(`${this.endpoint}/widget`, {
            params: {
                leadId,
            },
        })
    }

    autocomplete(search?: string, currentId?: string): Promise<Workflow[]> {
        return client.get(`${this.endpoint}/autocomplete`, {
            params: {
                search,
                currentId,
            },
        })
    }

    getEnrolled(workflowId: string): Promise<string[]> {
        return client.get(`${this.endpoint}/enrolled`, {
            params: {
                workflowId,
            },
        })
    }

    checkWorkflowList(typeName: string, entityIds: string[]) {
        return client.get(`${this.endpoint}/listCheck`, { params: { typeName, entityIds } })
    }
}
