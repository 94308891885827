import { defineStore } from "pinia"
import { TaskService } from "@/shared/services/task"
import { ToastService } from "@/shared/services/toast"
import { Task, TaskResponse, TaskSessionBody } from "@/shared/models/task"
import { TableState } from "@/shared/models/table"
import { ToastModel } from "@/shared/models/toast-model"
import { useLeadsStore } from "@/stores/leads"
import { useTimelineStore } from "@/stores/timeline"
import { TimelineItem } from "@/shared/models/timelineItem"
import { isTask } from "@/shared/utils/helpers"
import { Tag } from "@/shared/models/tag"
import { useGridCollectionStore } from "@/stores/grid-collection"
import { useSocketStore } from "@/stores/socket"
import { Collection } from "@/shared/models/collection"
import { GgmsError } from "@/shared/services/client"

export const useTaskStore = defineStore("task", {
    state: () => ({
        tableState: {
            data: [],
            selectedData: [],
            currentPage: 0,
            lastPage: 0,
            pageLength: 50,
            numberOfPages: 0,
            total: 0,
            isAllSelected: false,
            isLoading: false,
            firstIds: [],
        } as TableState<Task>,
        taskService: new TaskService(),
        toastService: new ToastService(),
        activeTasks: [] as Task[],
        activeTask: {} as Task,
        isActiveTaskSession: false,
        isLeadProfile: false,
        leadsStore: useLeadsStore(),
        timelineStore: useTimelineStore(),
        gridCollectionStore: useGridCollectionStore(),
        socketStore: useSocketStore(),
    }),
    actions: {
        async getTasks(quickFilters?: string) {
            try {
                this.tableState.isLoading = true

                let lastId = null
                let offset = null

                // If we're going to the next page, use keyset pagination.
                if (this.tableState.currentPage === (this.tableState?.lastPage ?? 0) + 1) {
                    lastId =
                        this.tableState.data.length > 0
                            ? this.tableState.data[this.tableState.data?.length - 1]._id
                            : null
                }
                // If we're jumping to a specific page, use offset-based pagination.
                else if (
                    this.tableState.currentPage !== (this.tableState?.lastPage ?? 0) + 1 &&
                    this.tableState.currentPage > 1
                ) {
                    offset = (this.tableState.currentPage - 1) * this.tableState.pageLength
                }

                let filtersGroups = this.gridCollectionStore?.grid?.filtersGroups?.length
                    ? encodeURIComponent(JSON.stringify(this.gridCollectionStore.grid.filtersGroups))
                    : undefined

                if (this.gridCollectionStore.collectionTableState.type === "lead-profile") {
                    filtersGroups = quickFilters ?? undefined
                    this.tableState.order = -1
                    this.tableState.pageLength = 2
                } else {
                    this.clearLeadPageQuickFilters()
                }

                const response = await this.taskService.getAll({
                    length: this.tableState.pageLength,
                    column: this.tableState.column,
                    order: this.tableState.order,
                    search: this.tableState.search,
                    filtersGroups,
                    lastId,
                    offset,
                    ...(this.leadsStore?.lead?._id && { leadId: this.leadsStore.lead._id }),
                })

                // Store the first ID of the new page.
                if (this.tableState.firstIds && this.tableState.currentPage) {
                    this.tableState.firstIds[this.tableState.currentPage - 1] = response.data[0]?._id
                }

                this.tableState.selectedData = []
                this.tableState.data = response.data
                this.tableState.total = response.total
                this.tableState.numberOfPages = Math.ceil(this.tableState.total / this.tableState.pageLength)
                this.tableState.lastPage = this.tableState.currentPage
            } finally {
                this.tableState.isLoading = false
            }
        },

        clearLeadPageQuickFilters() {
            this.tableState.order = undefined
            this.tableState.pageLength = 50
        },

        resetTableState() {
            this.tableState.data = []
            this.tableState.total = 0
            this.tableState.selectedData = []
            this.tableState.numberOfPages = Math.ceil(1)
        },

        getTask(id: string): Promise<TaskResponse> {
            return this.taskService.getOne(id)
        },

        bulkDelete(ids: string[], all: boolean) {
            return this.taskService.bulkDelete(ids, all)
        },

        async bulkUpdate(ids: string[], all: boolean, status: string) {
            this.tableState.isLoading = true
            const response = await this.taskService.bulkUpdate(ids, all, status)
            if (response.updatedCount) {
                ids.forEach((id) => {
                    const updatedTaskIndex = this.tableState.data.findIndex(({ _id }) => _id === id)
                    if (updatedTaskIndex >= 0) {
                        this.tableState.data[updatedTaskIndex].status = status
                    }
                    const updatedActiveTaskIndex = this.activeTasks.findIndex(({ _id }) => _id === id)
                    if (updatedActiveTaskIndex >= 0) {
                        this.activeTasks[updatedActiveTaskIndex].status = status
                    }
                })
                if (all) {
                    this.tableState.data = this.tableState.data.map((task) => {
                        task.status = status
                        return task
                    })
                    this.activeTasks = this.activeTasks.map((task) => {
                        task.status = status
                        return task
                    })
                }
            }
            this.checkIfGoToNextTask(ids)
            this.tableState.isLoading = false
            return response
        },

        async updateTaskStatus(id: string, status: string) {
            await this.taskService.updateTaskStatus(id, status)

            this.toastService.addToast({
                type: "success",
                message: status === "completed" ? "Task successfully completed." : "Task status updated to: To do",
            })

            const updatedTimelineTask = this.timelineStore.timelineItems.find(
                (timelineItem) => timelineItem?.item?._id === id
            )

            if (updatedTimelineTask && isTask(updatedTimelineTask.item)) {
                updatedTimelineTask.item.status = status
            }
        },

        async deleteOne(id: string) {
            await this.taskService.deleteOne(id)
            if (this.leadsStore?.lead?.tasks?.data?.length) {
                this.leadsStore.lead.tasks.data = this.leadsStore.lead.tasks.data.filter((task) => task._id !== id)
                this.leadsStore.lead.tasks.total -= 1
            }

            const deletedTimelineTask = this.timelineStore.timelineItems.find(
                (timelineItem) => timelineItem?.item?._id === id
            )

            if (this.timelineStore.timelineItems.length) {
                this.timelineStore.timelineItems = this.timelineStore.timelineItems.filter(
                    (timelineItem) => timelineItem?.item?._id && timelineItem.item._id !== id
                )
            }

            if (!deletedTimelineTask) {
                return
            }

            this.timelineStore.totalTimelineItems -= 1
            if (deletedTimelineTask._id === this.timelineStore.pinnedTimelineItem?._id) {
                this.timelineStore.pinnedTimelineItem = {} as TimelineItem
            }
        },

        async createTask(data: Task) {
            const { task: addedTask } = await this.taskService.createTask(data)

            if (!data.collectionId) {
                return
            }

            const filterGroup = {
                filters: [
                    {
                        field: "_id",
                        type: "string",
                        rule: "isInIds",
                        displayName: "Id",
                        value: [addedTask._id],
                    },
                ],
            }

            const collections = this.gridCollectionStore.collectionTableState.data

            const collection = collections.find((collection) => collection._id === data.collectionId)

            if (collection) {
                collection.filtersGroups.push(filterGroup)
            }

            await this.gridCollectionStore.updateCollection(data.collectionId, collection as Collection)

            if (!data.leadId) {
                return
            }
            if (this.leadsStore?.lead?.tasks?.data?.length) {
                this.leadsStore.lead.tasks.data.unshift(addedTask)
                this.leadsStore.lead.tasks.total += 1
            } else {
                this.leadsStore.lead.tasks.data = [addedTask]
                this.leadsStore.lead.tasks.total = 1
            }

            //We need to make a request so we get back the timelineItem Id
            await this.timelineStore.getTimeline(data.leadId)
        },

        async updateTask(id: string, data: Task) {
            const { task: updatedTask } = await this.taskService.updateTask(id, data)
            const updatedTaskIndex = this.tableState.data.findIndex((task) => task._id === id)
            if (updatedTaskIndex >= 0) {
                this.tableState.data[updatedTaskIndex].status = "completed"
            }
            if (!data.leadId) {
                return
            }
            if (this.leadsStore?.lead?.tasks?.data?.length) {
                this.leadsStore.lead.tasks.data = this.leadsStore.lead.tasks.data.map((note) =>
                    note._id === updatedTask._id ? updatedTask : note
                )
            }
            if (this.timelineStore?.timelineItems) {
                this.timelineStore.timelineItems = this.timelineStore.timelineItems.map((timelineItem) => {
                    if (timelineItem?.item?._id === updatedTask._id) {
                        return {
                            ...timelineItem,
                            item: updatedTask,
                        }
                    }
                    return {
                        ...timelineItem,
                    }
                })
            }

            if (updatedTask._id === this.timelineStore.pinnedTimelineItem?.item?._id) {
                this.timelineStore.pinnedTimelineItem.item = Object.assign({}, updatedTask)
            }
        },

        async getTasksGrid() {
            try {
                this.tableState.isLoading = true
                const response = await this.gridCollectionStore.getGrid("tasks")

                await this.getTasks()
                return response
            } finally {
                this.tableState.isLoading = false
            }
        },

        clearFilters() {
            this.gridCollectionStore.grid.filtersGroups = []
            this.gridCollectionStore.filtersGroups = []

            this.updateTasksGrid()
        },

        async updateTasksGrid() {
            try {
                this.tableState.isLoading = true
                if (!this.gridCollectionStore.grid) return
                this.gridCollectionStore.grid.filtersGroups = this.gridCollectionStore.filtersGroups || []

                const response = await this.gridCollectionStore.updateGrid("tasks", this.gridCollectionStore.grid)
                this.gridCollectionStore.gridResponse = response
                this.gridCollectionStore.filtersGroups = this.gridCollectionStore.gridResponse.grid.filtersGroups
                this.gridCollectionStore.grid = this.gridCollectionStore.gridResponse.grid

                await this.getTasks()
            } finally {
                this.tableState.isLoading = false
            }
        },

        async createTaskSession(data: TaskSessionBody) {
            await this.taskService.createTaskSession(data)
            this.isActiveTaskSession = true
            this.tableState.selectedData = []
        },

        async getActiveTaskSession() {
            const response = await this.taskService.getActiveTaskSession()
            if (response && response.session !== null) {
                this.activeTasks = response.session?.items
                this.getFirstPendingActiveTask()
                this.isActiveTaskSession = true
            } else {
                this.activeTasks = []
                this.isActiveTaskSession = false
            }
        },

        getFirstPendingActiveTask() {
            const savedActiveTaskIndex = sessionStorage.getItem("savedActiveTaskIndex")
            if (this.activeTasks && Array.isArray(this.activeTasks)) {
                this.activeTask = savedActiveTaskIndex
                    ? this.activeTasks[parseInt(savedActiveTaskIndex)]
                    : this.activeTasks.find((task) => task.status === "pending") || ({} as Task)
            }
        },

        async closeTaskSession() {
            sessionStorage.removeItem("savedActiveTaskIndex")
            await this.taskService.closeTaskSession()
            this.isActiveTaskSession = false
            this.tableState.selectedData = []
        },

        checkIfAllActiveTasksCompleted(): boolean {
            return this.activeTasks.every((task) => task.status === "completed")
        },

        checkIfGoToNextTask(ids: string[]) {
            if (!ids.includes(this.activeTask._id)) {
                return
            }
            const currentIndex = this.activeTasks.findIndex((task) => task._id === this.activeTask._id)
            const nextIndex = this.activeTasks.slice(currentIndex).findIndex(({ status }) => status === "pending")
            if (nextIndex !== -1) {
                this.activeTask = this.activeTasks[currentIndex + nextIndex]
                return
            }
            const previousIndex = this.activeTasks.findIndex(({ status }) => status === "pending")
            if (previousIndex !== -1) {
                this.activeTask = this.activeTasks[previousIndex]
                return
            }
            sessionStorage.removeItem("savedActiveTaskIndex")
            this.isActiveTaskSession = false
        },

        async bulkUpdateTagOnTask(taskIds: string[], toAdd: Tag[], toRemove: Tag[], all: boolean) {
            const { job } = await this.taskService.bulkUpdateTagOnTask(taskIds, toAdd, toRemove, all)
            this.socketStore.addJob(job)
        },

        async checkTaskList(typename: string, taskIds: string[]) {
            try {
                return this.taskService.checkTaskList(typename, taskIds)
            } catch (error) {
                const err = error as GgmsError
                if (err.code === "ValidationError") {
                    this.toastService.addToast({
                        type: "error",
                        message: err.message,
                    })
                }
            }
        },
    },
})
