<template>
    <div :id="contactOverlayTarget" class="h-6 w-6 relative">
        <PlusCircleIcon
            ref="plusCircleIcon"
            class="h-full w-full text-primary-color-600 inline-block hover:text-primary-color-700 cursor-pointer"
            @click="toggleMenu"
        />
        <div class="z-10">
            <OverlayPanel
                ref="menu"
                :appendTo="`#${contactOverlayTarget}`"
                class="ring-1 ring-black ring-opacity-5 shadow-lg rounded-md bg-white min-w-[288px] w-full !top-4 right-1"
                :class="overlayPanelClass"
                @hide="clearLeads"
            >
                <div class="flex flex-col h-full z-10 w-full">
                    <div class="w-full border-b p-4">
                        <GgmsInput
                            v-model="searchTerm"
                            styleType="secondary"
                            :placeholder="placeholder"
                            :small="true"
                            @keydown.enter="selectUnknownContact(searchTerm)"
                        ></GgmsInput>
                    </div>

                    <div class="relative max-h-80 overflow-y-auto">
                        <div v-if="!possibleContacts.length && isSearchingContact" class="py-8"></div>
                        <template v-else>
                            <div
                                v-for="contact in possibleContacts"
                                :key="contact._id"
                                tabindex="0"
                                class="z-10 rounded-md flex items-center gap-x-2 outline-primary-color-500 hover:bg-gray-50 px-4 py-3 cursor-pointer"
                                @click="selectContact(contact)"
                            >
                                <div
                                    class="min-w-[32px] min-h-[32px] rounded-full bg-gray-300 items-center justify-center flex"
                                >
                                    {{ getInitials(contact.fullName) }}
                                </div>
                                <!-- Set the searched contacts details width -->
                                <div class="flex flex-col w-[calc(100%-40px)]">
                                    {{ contact.fullName }}
                                    <p v-if="isSMSType && contact?.phone" class="text-gray-500">
                                        ({{ contact.phone.number }})
                                    </p>
                                    <p v-else class="text-gray-500 truncate">({{ contact.email }})</p>
                                </div>
                            </div>
                        </template>
                        <GgmsLoading v-if="isSearchingContact" />
                    </div>
                </div>
            </OverlayPanel>
        </div>
    </div>
</template>

<script setup lang="ts">
import { PropType, ref, watch, computed, inject } from "vue"
import GgmsInput from "@/components/GgmsInput.vue"
import GgmsLoading from "@/components/GgmsLoading.vue"
import { PlusCircleIcon } from "@heroicons/vue/solid"
import { useInboxStore } from "@/stores/inbox"
import { useLeadsStore } from "@/stores/leads"
import { ToastService } from "@/shared/services/toast"
import { InboxContact } from "@/shared/models/inbox"
import { debounce, getFullName, getInitials, isValidEmail, isValidPhoneNumber } from "@/shared/utils/helpers"

const inboxStore = useInboxStore()
const leadStore = useLeadsStore()
const toastService = new ToastService()

const props = defineProps({
    type: {
        type: String,
        default: "sms",
        validator: (value: string) => ["sms", "email"].includes(value),
    },
    recipients: {
        type: Array as PropType<InboxContact[]>,
        default: () => [],
    },
    toFieldLength: {
        type: Number,
        default: 0,
    },
    fieldName: {
        type: String,
        default: "to",
    },
})

const emit = defineEmits(["close", "select"])

const contactOverlayTarget = inject(`contactOverlayTarget-${props.fieldName}`) as string

const menu = ref()
const plusCircleIcon = ref()
const possibleContacts = ref<InboxContact[]>([])
const isSMSType = computed(() => props.type === "sms")
const placeholder = computed(() => (isSMSType.value ? "Enter name or phone number" : "Enter name or email address"))
const isSearchingContact = ref(false)

const searchTerm = ref("")
const overlayPanelFloat = ref("right")
const overlayPanelClass = computed(() => (overlayPanelFloat.value === "right" ? "!left-0" : "!left-auto"))

function selectContact(contact: InboxContact) {
    menu.value.hide()
    emit("select", contact)
}

// select those users that are not leads or agents
function selectUnknownContact(searchTerm: string) {
    if (!searchTerm) {
        return
    }
    if (isValidEmail(searchTerm)) {
        selectContact({ email: searchTerm } as InboxContact)
        return
    }
    if (isValidPhoneNumber(searchTerm)) {
        selectContact({ phone: { number: searchTerm } } as InboxContact)
        return
    }
    toastService.addToast({
        message: "Please enter a valid email address or phone number",
        type: "error",
    })
}

function clearLeads() {
    possibleContacts.value = []
    searchTerm.value = ""
}

async function searchContacts() {
    try {
        isSearchingContact.value = true
        const startingSearchTerm = searchTerm.value

        const response = isSMSType.value
            ? await searchContactsByPhone()
            : await inboxStore.autocomplete(searchTerm.value, 20)

        // need to check if the search term has changed since the request was made, to prevent dialog from filling with data after closing and reopening it
        if (!startingSearchTerm || startingSearchTerm !== searchTerm.value) {
            possibleContacts.value = []
            return
        }

        possibleContacts.value = response.filter(
            (contact) =>
                !props.recipients.some((recipient) =>
                    isSMSType.value
                        ? recipient.phone?.number === contact.phone?.number
                        : recipient.email === contact.email
                )
        )
    } finally {
        isSearchingContact.value = false
    }
}

async function searchContactsByPhone() {
    const filters = [{ field: "phones.number", rule: "isNotEmpty" }]
    const response = await leadStore.leadService.getAll({
        start: 0,
        length: 10,
        search: searchTerm.value,
        filters,
    })

    // convert the leads to inbox contacts
    const lead = response.data?.[0]

    if (!lead?.phones?.length) {
        return [] as InboxContact[]
    }
    return lead.phones.map((phone) => {
        const fullName = getFullName(lead?.firstName, lead?.lastName)
        return {
            fullName,
            phone,
            _id: lead._id,
            doNotDisturb: lead.doNotDisturb,
            marketingPreferences: lead.marketingPreferences,
        } as InboxContact
    })
}

function toggleMenu(event?: Event) {
    // get the X position of the plus circle icon
    const plusCircleIconX = plusCircleIcon.value.getBoundingClientRect().x
    // set the float of the overlay panel to the appropriate side based on the X position of the plus circle icon
    overlayPanelFloat.value = plusCircleIconX > window.innerWidth / 2 ? "left" : "right"

    menu.value?.toggle(event)
}

watch(
    searchTerm,
    debounce(() => {
        if (!searchTerm.value) {
            possibleContacts.value = []
            return
        }
        searchContacts()
    }, 350)
)
</script>
