import { defineStore } from "pinia"
import { TagService } from "@/shared/services/tag"
import { Tag, CachedTags } from "@/shared/models/tag"
import { TableState } from "@/shared/models/table"
import { ToastService } from "@/shared/services/toast"
import { useSocketStore } from "@/stores/socket"
import { useGridCollectionStore } from "@/stores/grid-collection"
import { GgmsError } from "@/shared/services/client"

export const useTagStore = defineStore("tag", {
    state: () => ({
        tableState: {
            data: [],
            selectedData: [],
            currentPage: 0,
            lastPage: 0,
            pageLength: 50,
            numberOfPages: 0,
            total: 0,
            isAllSelected: false,
            isLoading: false,
            error: {} as GgmsError,
            search: "",
            firstIds: [],
        } as TableState<Tag>,
        tagService: new TagService(),
        toastService: new ToastService(),
        socketStore: useSocketStore(),
        gridCollectionStore: useGridCollectionStore(),
        cachedTags: {} as CachedTags,
        phoneCallTags: [] as Tag[],
        tagTypes: [
            { label: "Lead", value: "lead" },
            { label: "Task", value: "task" },
            { label: "Messaging Template", value: "messaging-template" },
            { label: "Form", value: "form" },
            { label: "Phone Call", value: "call" },
            { label: "Contact Stage", value: "contact-stage" },
            { label: "Origin", value: "origin" },
            { label: "Listing", value: "listing" },
        ],
    }),
    actions: {
        async getTags() {
            try {
                this.tableState.isLoading = true
                this.tableState.error = {} as GgmsError

                let lastId = null
                let offset = null

                // If we're going to the next page, use keyset pagination.
                if (this.tableState.currentPage === (this.tableState?.lastPage ?? 0) + 1) {
                    lastId =
                        this.tableState.data.length > 0
                            ? this.tableState.data[this.tableState.data?.length - 1]._id
                            : null
                }
                // If we're jumping to a specific page, use offset-based pagination.
                else if (
                    this.tableState.currentPage !== (this.tableState?.lastPage ?? 0) + 1 &&
                    this.tableState.currentPage > 1
                ) {
                    offset = (this.tableState.currentPage - 1) * this.tableState.pageLength
                }

                let filtersGroups
                // use filtersGroups only if we're on tags page
                if (this.gridCollectionStore.collectionType === "tag") {
                    filtersGroups = this.gridCollectionStore?.grid?.filtersGroups?.length
                        ? encodeURIComponent(JSON.stringify(this.gridCollectionStore.grid.filtersGroups))
                        : undefined
                }

                const response = await this.tagService.getAll({
                    length: this.tableState.pageLength,
                    column: this.tableState.column,
                    order: this.tableState.order,
                    search: this.tableState.search,
                    typeName: this.tableState.typeName,
                    lastId,
                    offset,
                    filtersGroups,
                })

                // Store the first ID of the new page.
                if (this.tableState.firstIds && this.tableState.currentPage) {
                    this.tableState.firstIds[this.tableState.currentPage - 1] = response.data[0]?._id
                }

                this.tableState.selectedData = []
                this.tableState.data = response.data
                this.tableState.total = response.total
                this.tableState.numberOfPages = Math.ceil(this.tableState.total / this.tableState.pageLength)
                this.tableState.lastPage = this.tableState.currentPage
            } catch (error) {
                this.tableState.error = error as GgmsError
            } finally {
                this.tableState.isLoading = false
            }
        },

        async getAllTagsBySearch(search: string) {
            try {
                this.tableState.isLoading = true

                const response = await this.tagService.getAll({
                    start: this.tableState.currentPage,
                    length: this.tableState.pageLength,
                    column: this.tableState.column,
                    order: this.tableState.order,
                    search: search,
                    ids: this.gridCollectionStore?.ids || undefined,
                    typeName: this.tableState.typeName,
                })
                return response.data
            } catch (error) {
                this.tableState.error = error as GgmsError
            } finally {
                this.tableState.isLoading = false
            }
        },

        resetTableState() {
            this.tableState.data = []
            this.tableState.total = 0
            this.tableState.selectedData = []
            this.tableState.numberOfPages = Math.ceil(1)
            this.tableState.typeName = undefined
        },

        async getTagGrid() {
            try {
                this.tableState.isLoading = true
                const response = await this.gridCollectionStore.getGrid("tags")

                await this.getTags()
                return response
            } finally {
                this.tableState.isLoading = false
            }
        },

        async updateTagGrid() {
            try {
                this.tableState.isLoading = true
                if (!this.gridCollectionStore.grid) return
                this.gridCollectionStore.grid.filtersGroups = this.gridCollectionStore.filtersGroups || []

                const response = await this.gridCollectionStore.updateGrid("tags", this.gridCollectionStore.grid)
                this.gridCollectionStore.gridResponse = response
                this.gridCollectionStore.grid = this.gridCollectionStore.gridResponse.grid
                this.gridCollectionStore.filtersGroups = this.gridCollectionStore.grid.filtersGroups

                await this.getTags()
            } finally {
                this.tableState.isLoading = false
            }
        },

        async createTag(tag: Tag) {
            try {
                this.tableState.error = {} as GgmsError
                await this.tagService.createTag(tag)
                this.tableState.data.unshift(tag)
            } catch (error) {
                this.tableState.error = error as GgmsError
                throw error
            }
        },

        async updateTag(id: string, tag: Partial<Tag>) {
            try {
                this.tableState.error = {} as GgmsError
                await this.tagService.updateTag(id, tag)
                const updatedTagIndex = this.tableState.data.findIndex((tag) => tag._id === id)
                if (updatedTagIndex !== -1) {
                    this.tableState.data[updatedTagIndex] = tag as Tag
                    this.toastService.addToast({
                        type: "success",
                        message: `${tag.displayName} updated.`,
                    })
                }
            } catch (error) {
                this.tableState.error = error as GgmsError
            }
        },

        async deleteTag(id: string, acknowledged?: boolean) {
            try {
                await this.tagService.deleteTag(id, acknowledged)
                const deleteTag = this.tableState.data.find((tag) => tag._id === id)
                if (deleteTag) {
                    this.tableState.data = this.tableState.data.filter((tag) => tag._id !== id)
                    this.toastService.addToast({
                        type: "success",
                        message: `${deleteTag.displayName} deleted.`,
                    })
                }
                this.tableState.total -= 1
            } catch (err) {
                const error = err as GgmsError
                if (error?.code === "TagsInUse") {
                    throw error?.message
                }
            }
        },

        async deleteTags(acknowledged?: boolean) {
            try {
                const ids = this.tableState.selectedData?.map((tag) => tag._id) || []
                const tagIds = this.tableState.isAllSelected ? [] : ids
                await this.tagService.deleteTags(tagIds, !!this.tableState.isAllSelected, acknowledged)
                this.tableState.data = this.tableState.data.filter((tag) => !ids.includes(tag._id))
            } catch (err) {
                const error = err as GgmsError
                if (error?.code === "TagsInUse") {
                    throw error?.message
                }
            }
        },

        async bulkUpdateTags(updates: Partial<Tag> = {}) {
            try {
                const ids = this.tableState.selectedData?.map((tag) => tag._id) || []
                const tagIds = this.tableState.isAllSelected ? [] : ids
                const { updatedCount } = await this.tagService.bulkUpdateTags(
                    tagIds,
                    !!this.tableState.isAllSelected,
                    updates
                )
                this.toastService.addToast({
                    type: "success",
                    message: `${updatedCount} tag(s) updated.`,
                })
            } catch (err) {
                console.log(err)
            }
        },

        async getPhoneCallTags() {
            try {
                const response = await this.tagService.getAll({
                    typeName: "call",
                })
                this.phoneCallTags = response.data
            } catch (err) {
                console.log(err)
            }
        },

        async getCachedTags() {
            const response = await this.tagService.getCachedTags()
            if (response && response.data) {
                response.data.forEach((tag) => {
                    this.cachedTags[tag._id] = tag
                })
            } else {
                this.cachedTags = {}
            }
        },

        getTagFromCache(id: string) {
            return this.cachedTags[id]
        },

        async getTagsForLeads() {
            const tags = await this.tagService.getAll({
                start: this.tableState.currentPage,
                length: this.tableState.pageLength,
                column: this.tableState.column,
                order: this.tableState.order,
                search: this.tableState.search,
                typeName: "lead",
            })

            return tags.data
        },

        async getTagsForTasks() {
            const tags = await this.tagService.getAll({
                start: this.tableState.currentPage,
                length: this.tableState.pageLength,
                column: this.tableState.column,
                order: this.tableState.order,
                search: this.tableState.search,
                typeName: "task",
            })
            return tags.data
        },

        async checkTagList(typename: string, entityIds: string[]) {
            try {
                return this.tagService.checkTagList(typename, entityIds)
            } catch (error) {
                const err = error as GgmsError
                if (err.code === "ValidationError") {
                    this.toastService.addToast({
                        type: "error",
                        message: err.message,
                    })
                }
            }
        },
    },
})
