<template>
    <div class="flex flex-col gap-y-4 mx-2 mt-1.5 mb-5">
        <!-- Collapsed State -->
        <div v-if="collapsed" class="flex items-center justify-center">
            <GgmsButton styleType="secondary" :classes="['px-2 py-1']" @click="toggleOverlay">
                <component :is="OfficeBuildingIcon" class="h-5 w-5 text-gray-500" />
            </GgmsButton>
        </div>

        <div v-if="!collapsed" class="flex items-center justify-between gap-x-2">
            <img v-if="agencyLogo" :src="agencyLogo" alt="agency-logo" class="max-w-[185px] max-h-6 m-auto" />
            <span v-else class="text-gray-600 text-xl leading-6 line-clamp-1"> {{ selectedAgency?.agencyName }}</span>
            <GgmsSearchDropdown
                :value="selectedAgency"
                :options="agencies"
                optionAttribute="agencyName"
                title="Select Agency"
                v-slot="{ toggle, visible }"
                @onSelect="changeAgency"
            >
                <GgmsButton styleType="secondary" :classes="['px-1 py-1']" :small="false" @click.stop="toggle">
                    <component
                        :is="visible ? ChevronUpIcon : ChevronDownIcon"
                        class="h-5 w-5 text-gray-500"
                    ></component>
                </GgmsButton>
            </GgmsSearchDropdown>
        </div>
        <GgmsDropdown
            v-if="!collapsed"
            :value="selectedDomain"
            :options="domains"
            optionLabel="url"
            class="h-[38px] w-full agency-select-dropdown"
            panelClass="max-w-[310px] truncate text-sm font-normal text-gray-700"
            @onChange="changeDomain"
        >
            <template #customLabel="{ slotProps }">
                {{ getDomainHeader(slotProps.value) }}
            </template>
        </GgmsDropdown>

        <!-- Overlay Panel -->
        <OverlayPanel ref="overlayPanel" :dismissable="true" class="w-80 p-4">
            <div class="flex items-center justify-between gap-x-2">
                <img v-if="agencyLogo" :src="agencyLogo" alt="agency-logo" class="max-w-[185px] max-h-6 m-auto" />
                <span v-else class="text-gray-600 text-xl leading-6 line-clamp-1">
                    {{ selectedAgency?.agencyName }}
                </span>
                <GgmsSearchDropdown
                    :value="selectedAgency"
                    :options="agencies"
                    optionAttribute="agencyName"
                    title="Select Agency"
                    v-slot="{ toggle, visible }"
                    @onSelect="changeAgency"
                >
                    <GgmsButton styleType="secondary" :classes="['px-1 py-1']" :small="false" @click.stop="toggle">
                        <component
                            :is="visible ? ChevronUpIcon : ChevronDownIcon"
                            class="h-5 w-5 text-gray-500"
                        ></component>
                    </GgmsButton>
                </GgmsSearchDropdown>
            </div>

            <GgmsDropdown
                :value="selectedDomain"
                :options="domains"
                optionLabel="url"
                class="h-[38px] w-full agency-select-dropdown mt-2"
                panelClass="max-w-[310px] truncate text-sm font-normal text-gray-700"
                @onChange="changeDomain"
            >
                <template #customLabel="{ slotProps }">
                    {{ getDomainHeader(slotProps.value) }}
                </template>
            </GgmsDropdown>
        </OverlayPanel>
    </div>
</template>

<script lang="ts" setup>
import { ref, computed, reactive, watch, onMounted } from "vue"
import GgmsSearchDropdown from "@/components/GgmsSearchDropdown.vue"
import GgmsButton from "@/components/GgmsButton.vue"
import GgmsDropdown from "@/components/GgmsDropdown.vue"
import OverlayPanel from "primevue/overlaypanel"
import { ChevronDownIcon, ChevronUpIcon } from "@heroicons/vue/outline"
import { useAgencyStore } from "@/stores/agency"
import { useConfigStore } from "@/stores/config"
import { useAgencyThemeStore } from "@/stores/agency-theme"
import { useNavigationSidebarStore } from "@/stores/navigation-sidebar"
import { AgencyDomain, Agency } from "@/shared/models/agency"
import { getCookieValue, getToken, setCookie } from "@/shared/utils/helpers"
import OfficeBuildingIcon from "@/components/icons/OfficeBuildingIcon.vue"

const agencyStore = useAgencyStore()
const configStore = useConfigStore()
const agencyThemeStore = useAgencyThemeStore()
const navigationSidebarStore = useNavigationSidebarStore()

interface Props {
    collapsed: boolean
}

const props = defineProps<Props>()

// State for showing/hiding the overlay
const overlayPanel = ref(null)

// Agencies
const selectedAgency = ref(getCookieValue("selectedAgency") ? JSON.parse(getCookieValue("selectedAgency") || "{}") : {})
const agencies = computed(() => agencyStore.availableAgencies)

// Domains
const allDomain = reactive({ _id: "all", url: "All domains" } as AgencyDomain)
const selectedDomain = ref(
    getCookieValue("selectedDomain") ? JSON.parse(getCookieValue("selectedDomain") || "{}") : structuredClone(allDomain)
)

const domains = computed(() => {
    if (selectedAgency.value?.agencyDomains?.length > 1) {
        return [allDomain, ...selectedAgency.value?.agencyDomains]
    }
    return selectedAgency.value?.agencyDomains || []
})

const agencyLogo = computed(() => agencyThemeStore.agencyTheme?.logo?.url || undefined)

function saveSelectedDomainToLocalStorage(value: AgencyDomain) {
    setCookie("selectedDomain", JSON.stringify(value), true)
}

function saveSelectedAgencyToLocalStorage(value: Agency) {
    setCookie("selectedAgency", JSON.stringify(value), true)
}

function getDomainHeader(header: AgencyDomain) {
    return header._id === "all" ? `${header.url} (${selectedAgency.value?.agencyDomains?.length || ""})` : header.url
}

async function changeAgency(agency: Agency) {
    navigationSidebarStore.toggleSidebar(false)
    selectedAgency.value = structuredClone(agency)
    selectedDomain.value = {}
    saveSelectedAgencyToLocalStorage(selectedAgency.value)
    if (selectedAgency.value?.agencyDomains?.length === 1) {
        selectedDomain.value = structuredClone(selectedAgency.value.agencyDomains[0])
    } else {
        selectedDomain.value = structuredClone(allDomain)
    }
    agencyStore.selectedAgency = structuredClone(selectedAgency.value)
    await saveDomain()
    redirectOnSelect()
}

async function changeDomain(domain: AgencyDomain) {
    navigationSidebarStore.toggleSidebar(false)
    selectedDomain.value = structuredClone(domain)
    await saveDomain()
    redirectOnSelect()
}

function toggleOverlay(event: Event) {
    if (overlayPanel.value) {
        overlayPanel.value.toggle(event)
    }
}

async function saveDomain() {
    saveSelectedDomainToLocalStorage(selectedDomain.value)
    agencyStore.selectedDomain = structuredClone(selectedDomain.value)
    agencyStore.selectedAgencyAndDomain = `${selectedAgency.value?._id}${selectedDomain.value?._id}`
    await configStore.getConfig()
}

function redirectOnSelect() {
    if (!process.env.VUE_APP_BASE_WEB_URL || window.location.hostname === "localhost") {
        return
    }

    if (selectedDomain.value.url !== "All domains") {
        const baseUrl = new URL(process.env.VUE_APP_BASE_WEB_URL)

        const authToken = getToken()

        setCookie("authToken", authToken, true)

        return window.location.replace(
            `${baseUrl.protocol}//${selectedDomain.value.url}.${baseUrl.hostname}${baseUrl.pathname}`
        )
    }

    return window.location.replace(process.env.VUE_APP_BASE_WEB_URL)
}

function redirectOnMounted() {
    if (!process.env.VUE_APP_BASE_WEB_URL) {
        return
    }

    const subdomains = domains.value.map((domain: AgencyDomain) => domain.url)
    const baseUrl = new URL(process.env.VUE_APP_BASE_WEB_URL)
    const baseUrlSubdomain = baseUrl.hostname.split(".")[0]
    const currentSubDomain = window.location.hostname.split(".")[0]

    if (baseUrlSubdomain === currentSubDomain && selectedDomain.value.url === "All domains") {
        return
    }

    if (baseUrlSubdomain !== currentSubDomain && subdomains.includes(currentSubDomain)) {
        selectedDomain.value = structuredClone(
            selectedAgency.value.agencyDomains.find((domain: AgencyDomain) => domain.url === currentSubDomain)
        )
        return
    }

    redirectOnSelect()
}

function handleDomainCRUD() {
    const domain = selectedAgency.value.agencyDomains.find(
        (agencyDomain: AgencyDomain) => agencyDomain._id === selectedDomain.value._id
    )

    // if the selected domain was removed or the agency has only one domain left
    if (!domain) {
        changeDomain(selectedAgency.value.agencyDomains[0])
        return
    }

    // if other domain was changed or removed than the selected one
    if (domain.url === selectedDomain.value.url) {
        return
    }

    // if the selected domain was updated
    changeDomain(domain)
}

onMounted(() => {
    if (selectedAgency.value?.agencyDomains?.length === 1) {
        selectedDomain.value = structuredClone(selectedAgency.value.agencyDomains[0])
    }
    if (Object.keys(selectedAgency.value).length) {
        agencyStore.selectedAgency = structuredClone(selectedAgency.value)
    }

    redirectOnMounted()
})

watch(
    () => agencyStore.agency,
    async (value, oldValue) => {
        selectedAgency.value = structuredClone(value)
        if (!getCookieValue("selectedAgency") || getCookieValue("selectedAgency") === "{}") {
            saveSelectedAgencyToLocalStorage(selectedAgency.value)
            if (selectedAgency.value?.agencyDomains?.length === 1) {
                selectedDomain.value = structuredClone(selectedAgency.value.agencyDomains[0])
            } else {
                selectedDomain.value = structuredClone(allDomain)
            }

            await saveDomain()
            return
        }

        if (!oldValue) {
            return
        }

        handleDomainCRUD()
    },
    { immediate: true }
)
</script>

<style>
.agency-select-dropdown .p-dropdown-label {
    @apply text-sm font-medium text-gray-700;
}
</style>
