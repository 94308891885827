import { PhoneCall } from "@/shared/models/phone-call"
import { Client } from "./client"
import Crud from "./crud"

const client = new Client()

export class PhoneCallService extends Crud<PhoneCall> {
    constructor() {
        super("/crm/calls")
    }

    setupCall(setupCallPayload: Pick<PhoneCall, "fromPhoneNumber" | "integration" | "toPhoneNumber">) {
        return client.post(`${this.endpoint}/setup`, setupCallPayload)
    }

    createCall(phoneCall: Partial<PhoneCall>) {
        return client.post(`${this.endpoint}`, phoneCall)
    }

    getCalls(): Promise<{ data: PhoneCall[]; total: number }> {
        return client.get(`${this.endpoint}?start=0&length=50&column=createdAt&order=-1`)
    }

    editCall(id: string, phoneCall: Partial<PhoneCall>) {
        return client.patch(`${this.endpoint}/${id}`, phoneCall)
    }

    lookupPhoneNumber(phoneNumber: string) {
        return client.post(`${this.endpoint}/lookup`, {
            phoneNumber,
        })
    }
}
